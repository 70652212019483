import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as styles from '../pages/styles/main.module.scss'
import {changeColor} from '../source/changeColor'
import Offerdesc from "../components/offerdesc/offerdesc"
import * as offerStyle from '../components/offerdesc/offer.module.scss'
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"


const Offer = ({data}) => {
  const allAirtableBanner = data.allAirtable.edges[0].node.data;
        const offersAirtable = allAirtableBanner;
        


  return (
    <>
      <Helmet>
       <meta name='description' content='Zajmujemy się kompleksową obsługą księgową firmy, czyli  głównie prowadzenie ewidencji księgowej, rozliczenia z organami podatkowymi i ZUS.'/>
    </Helmet>
    <Layout pageTitle="Oferta">
         <div className={styles.firstSection}  >
            <div className={`${styles.container}`} >
                <div className={`${styles.sectionTitle}`}>
                    <h1>{changeColor(offersAirtable.tytulOferty,2)}</h1>
                    <p>{offersAirtable.opis}</p> 
                </div>

                <div className={offerStyle.offerList}>
                  <Offerdesc dataSal="slide-up" dataSalDelay="200" offerTitle={offersAirtable.oferta1} offerDescription={offersAirtable.oferta1_opis} name='oferta1'/>
                  <Offerdesc dataSal="slide-up" dataSalDelay="300" offerTitle={offersAirtable.oferta2} offerDescription={offersAirtable.oferta2_opis} name='oferta2'/>
                  <Offerdesc dataSal="slide-up" dataSalDelay="400" offerTitle={offersAirtable.oferta3} offerDescription={offersAirtable.oferta3_opis} name='oferta3'/>
                  <Offerdesc dataSal="slide-up" dataSalDelay="500" offerTitle={offersAirtable.oferta4} offerDescription={offersAirtable.oferta4_opis} name='oferta4'/>
                  <Offerdesc dataSal="slide-up" dataSalDelay="600" offerTitle={offersAirtable.oferta5} offerDescription={offersAirtable.oferta5_opis} name='oferta5'/>
                </div>

                <div className={`${styles.container} ${offerStyle.offerText}`} data-sal='slide-up' data-sal-delay='100'>
                  <p>Jeśli nie znalazłeś na powyższej liście interesującej Cię usługi, skontaktuj się z nami, a my
                    pomożemy Ci nawet w niestandardowym problemie księgowym.
                  </p>
                  <a href="/kontakt" className={`${styles.btn} ${styles.btn__headerGreen}`}>Napisz do nas</a>
                </div>

            </div>
                <div className={offerStyle.quoteBlock} data-sal='slide-up' data-sal-delay='100'>
                  <div className={`${styles.container} ${offerStyle.quote}`}>
                    <p><q>Jakość pamięta się o wiele dłużej niż cenę</q> Gucci</p>
                  </div>

                </div>

                <div className={`${styles.firstSection} ${styles.container}`} data-sal='slide-up' data-sal-delay='100'>
                  <div className={`${styles.sectionTitle}`}>
                    <h2>{changeColor(offersAirtable.tytulWspolpraca,2)}</h2>
                  </div>

                  <div className={styles.flexBox}>
                    <div className={styles.w50} data-sal='slide-right' data-sal-delay='200' data-sal-duration="1">
                      <StaticImage src={'../images/wspolpraca.jpg'} alt="koszt współpracy Aliatan"/>
                    </div>
                    <div className={styles.w50}  data-sal='slide-left' data-sal-delay='200' data-sal-duration="1">
                      <p>{offersAirtable.opisWspolpraca}</p>
                    </div>
                  </div>
                </div>
        </div>
    </Layout>
    </>
  )
}

export default Offer

export const query = graphql `
{
    allAirtable(sort: {fields: data___Ids}, filter: {table: {eq: "Oferta"}}) {
      edges {
        node {
          data {
            Ids
            tytulOferty
            opis
            oferta1
            oferta1_opis
            oferta2
            oferta2_opis
            oferta3
            oferta3_opis
            oferta4
            oferta4_opis
            oferta5
            oferta5_opis
            tytulWspolpraca
            opisWspolpraca
          }
        }
      }
    }
  }`