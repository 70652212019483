import React from 'react';
import * as offerStyle from './offer.module.scss'

const Offerdesc = ({dataSal,dataSalDelay,offerTitle,offerDescription,name}) =>{

    let readMore = false;
    let nameDesc = name;
    const description = offerDescription.split('--');

    description.map((element,index) =>(index ===4)? readMore=true: null)


    const handleReadMoreClick = () =>{
        document.querySelector('.'+nameDesc).classList.remove(offerStyle.loadMore)
    }
    return(
        <div className={`${offerStyle.offerItem} ${offerStyle[name]}`}  data-sal={dataSal} data-delay={dataSalDelay}>  
            <div className={offerStyle.headOffer}>
                <h2> {offerTitle}</h2>
            </div>
           
            <div className={`${ (readMore===true)? offerStyle.loadMore: null} ${name}`}>
            <ul>
            {  description.map((listElement, index) =>{
                if(listElement!==''){
                    if(index===4){
                        
                        return (
                            <>
                                <li className={offerStyle.item} key={index}>{listElement}</li>
                                <li className={offerStyle.readMore} onClick={()=>handleReadMoreClick()}>Zobacz więcej</li>
                            </>
                        )
                    }
                    else{
                        return <li className={offerStyle.item} key={index}>{listElement}</li> 
                    }
                
                }
            })}
            </ul>
            </div>
        </div>
    )
}

export default Offerdesc;