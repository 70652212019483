// extracted by mini-css-extract-plugin
export var offerList = "offer-module--offerList--33Qpp";
export var offerItem = "offer-module--offerItem--30oce";
export var headOffer = "offer-module--headOffer--kKQQx";
export var loadMore = "offer-module--loadMore--3OY1e";
export var item = "offer-module--item--jJ29R";
export var readMore = "offer-module--readMore--RbkDI";
export var oferta1 = "offer-module--oferta1--TJZq_";
export var oferta2 = "offer-module--oferta2--RDPk0";
export var oferta3 = "offer-module--oferta3--25Kjv";
export var oferta4 = "offer-module--oferta4--3-UqN";
export var oferta5 = "offer-module--oferta5--2JeGb";
export var offerText = "offer-module--offerText--1nCDo";
export var quoteBlock = "offer-module--quoteBlock--2cQLR";
export var quote = "offer-module--quote--J4Bug";